var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('confirm',{ref:"confirm"}),(_vm.project.locais.length > 0)?[_vm._l((_vm.project.locais),function(local,idx){return _c('v-sheet',{key:idx,staticClass:"pa-3",attrs:{"flat":"","outlined":""}},[_c('v-card',[_c('v-card-title',{style:({
              backgroundColor: _vm.sheetColors[idx % _vm.sheetColors.length].background,
              color: _vm.sheetColors[idx % _vm.sheetColors.length].color,
            })},[_vm._v(" LOCAL "+_vm._s(idx + 1)+" "),_c('v-spacer'),(idx > 0)?_c('v-btn',{attrs:{"icon":"","right":"","small":"","color":"white"},on:{"click":function($event){return _vm.removeLocal(idx)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1),_c('v-card-text',{staticClass:"pa-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5","sm":"12"}},[_c('h-text-input',{attrs:{"label":"Nome do estabelecimento","required":"","dense":"","tip":"Informe o nome do estabelecimento"},model:{value:(local.estabelecimento),callback:function ($$v) {_vm.$set(local, "estabelecimento", $$v)},expression:"local.estabelecimento"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"12"}},[_c('h-text-input',{attrs:{"dense":"","label":"Cidade","tip":"Informe o nome da cidade onde o estabelecimento se situa"},model:{value:(local.cidade),callback:function ($$v) {_vm.$set(local, "cidade", $$v)},expression:"local.cidade"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"12"}},[_c('h-text-input',{attrs:{"dense":"","maxlength":2,"label":"Estado","tip":"Informe o estado (ex: SP, RJ, AL, AM, etc)"},model:{value:(local.estado),callback:function ($$v) {_vm.$set(local, "estado", $$v)},expression:"local.estado"}})],1),_c('v-col',{staticClass:"relative",attrs:{"cols":"12","md":"2","sm":"12"}},[_c('h-money-input',{attrs:{"options":{
                    precision: 0,
                    length: 7,
                    outputMask: 'Integer',
                    locale: 'pt-BR',
                  },"type":"number","label":"Lotação (pessoas)","tip":"Informe a lotação máxima aproximada do estabelecimento","outlined":true},model:{value:(local.lotacao),callback:function ($$v) {_vm.$set(local, "lotacao", $$v)},expression:"local.lotacao"}})],1)],1)],1),_c('v-card-actions',[_c('daily-expenses-estimate-form',{attrs:{"local":local,"sheet":_vm.sheetColors[idx % _vm.sheetColors.length]}})],1)],1)],1)}),_c('v-sheet',[_c('v-row',[_c('v-spacer'),_c('v-btn',{staticClass:"ma-6",attrs:{"color":"primary","large":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){return _vm.addNovoLocal()}}},[_vm._v(" Adicionar outro local "),_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-spacer'):_vm._e()],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }