<template>
  <div class="project__wrapper">
    <div class="absolute">
      <h-tip-dialog
        v-model="showErrors"
        title="Alerta"
        v-bind:tip="errors.join('<br/>')"
      ></h-tip-dialog>
    </div>

    <v-form v-if="project">
      <confirm ref="confirm"></confirm>
      <v-card elevation="0">
        <v-card-text>
          <v-stepper
            v-model="currentStep"
            :vertical="$vuetify.breakpoint.smAndDown"
          >
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <v-stepper-header>
                <v-stepper-step @click="step = 1" step="1">
                  Dados do Projeto
                </v-stepper-step>
                <v-divider class="w-1/3" />
                <v-stepper-step @click="step = 2" step="2">
                  Locais de Apresentação
                </v-stepper-step>
              </v-stepper-header>
            </div>

            <v-stepper-items>
              <v-stepper-step
                v-if="$vuetify.breakpoint.smAndDown"
                editable
                :complete="currentStep > 1"
                step="1"
              >
                Dados do Projeto
              </v-stepper-step>
              <v-stepper-content class="stepper-content" :step="1">
                <project-basic-info-step
                  :project="project"
                  @update:project="project = $event"
                />
              </v-stepper-content>

              <v-stepper-step
                v-if="$vuetify.breakpoint.smAndDown"
                editable
                :complete="currentStep == 2"
                :step="2"
              >
                Locais de Apresentação
              </v-stepper-step>
              <v-stepper-content class="stepper-content" step="2">
                <project-location-step
                  :project="project"
                  @update:project="project = $event"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions class="px-4">
          <v-spacer />
          <v-btn
            v-if="step != 1"
            right
            large
            color="primary"
            :loading="loading"
            @click="goBackStep"
          >
            <span>dados de projeto</span>
          </v-btn>
          <v-btn
            right
            large
            color="primary"
            :loading="loading"
            @click="goForward"
          >
            <span v-if="step == 1">SALVAR E AVANÇAR</span>
            <span v-else>SALVAR E FINALIZAR</span>
            <v-icon class="ml-2">mdi-content-save-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <div style="height:100px"></div>
    </v-form>

    <v-skeleton-loader
      v-else
      class="pa-16"
      type="article, article, actions"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import Confirm from "../../components/custom/HConfirm";
import ProjectBasicInfoStep from "../../components/calculator/ProjectBasicInfoStep";
import ProjectLocationStep from "../../components/calculator/ProjectLocationStep";
import HTipDialog from "../../components/custom/HTipDialog";

const isEmpty = (value) => {
  return !value || value === 0 || value.toString().trim().length == 0;
};

export default {
  components: {
    Confirm,
    ProjectBasicInfoStep,
    ProjectLocationStep,
    HTipDialog,
  },
  watch: {
    project: {
      handler(newVal, oldVal) {
        if (newVal && oldVal && !this.dirty) {
          this.dirty = true;
        }
      },
      deep: true,
    },
  },
  data: () => ({
    step: 1,
    project: null,
    dirty: false,
    loading: false,
    errors: [],
    showErrors: false,
    emptyProject: {
      titulo: "Novo Projeto",
      proponente: "",
      resumo: "",
      segmento: null,
      valorDireitosAutorais: 0,
      acervoImovel: "",
      questionnaire: {
        groups: [
          {
            questions: [{}],
          },
        ],
      },
      locais: [
        {
          dia: 0,
          estabelecimento: null,
          estado: "",
          cidade: "",
          lotacao: 0,
          qtApresentacoes: 1,
          despesas: [
            {
              local: {
                publico: 0,
                despesaTransporteChegada: 0,
                despesatransporteRedondezas: 0,
                despesaHospedagem: 0,
                despesaAlimentos: 0,
                despesaOutros: 0,
              },
              excursionista: {
                publico: 0,
                despesaTransporteChegada: 0,
                despesatransporteRedondezas: 0,
                despesaHospedagem: 0,
                despesaAlimentos: 0,
                despesaOutros: 0,
              },
              nacional: {
                publico: 0,
                despesaTransporteChegada: 0,
                despesatransporteRedondezas: 0,
                despesaHospedagem: 0,
                despesaAlimentos: 0,
                despesaOutros: 0,
              },
              internacional: {
                publico: 0,
                despesaTransporteChegada: 0,
                despesatransporteRedondezas: 0,
                despesaHospedagem: 0,
                despesaAlimentos: 0,
                despesaOutros: 0,
              },
            },
          ],
        },
      ],
      fontesRecurso: [
        {
          code: "FRPUB",
          value: "",
          label: "Públicos R$",
          hint:
            "Valor total originado de editais, leis de incentivo e fundos públicos",
        },
        {
          code: "FRPRV",
          value: "",
          label: "Privados R$",
          hint:
            "Valor total de patrocínio não incentivado, tais como doações e apoios financeiros de empresas privadas",
        },
        {
          code: "FRRP",
          value: "",
          label: "Próprios R$",
          hint:
            "Total de valores previstos por arrecadação com ingressos, vendas de produtos e capital próprio",
        },
      ],
    },
  }),
  computed: {
    currentStep: {
      get: function get() {
        return this.step;
      },
      set: function set(newValue) {
        this.step = newValue;
        if (newValue > 2) {
          this.step = 2;
        }

        if (newValue < 1) {
          this.step = 1;
        }
      },
    },
  },
  async beforeMount() {
    const projectId = this.$route.params.id;
    this.project = await this.fetchProject(projectId);
  },
  async beforeRouteLeave(to, from, next) {
    if (this.dirty) {
      const confirmed = await this.confirmBeforeLeaving();
      if (confirmed) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    async fetchProject(projectId) {
      let project = null;
      if (!projectId || projectId == "new") {
        project = {
          ...this.emptyProject,
        };
        this.project = project;
        await this.saveProject(0);
        this.$router.push("/projects/" + this.project._id);
      } else {
        project = await this.$api()
          .post("project/list", {
            _id: projectId,
          })
          .then((results) => {
            return results.data[0];
          })
          .catch((err) => {
            this.jsonData = err;
          });
      }

      return project;
    },
    async goForward() {
      if (await this.saveProject()) {
        if (this.step == 1) this.step++;
        else this.$router.push("/projects");
      }
    },
    async goBackStep() {
      if (await this.saveProject()) if (this.step != 1) this.step--;
    },
    async checkEssentials(_vm) {
      var checked = true;
      this.errors = [];
      var totalFR =
        (_vm.project.fontesRecurso &&
          _vm.project.fontesRecurso.reduce(
            (acc, item) => acc + (item.value - 0),
            0
          )) ||
        0;

      if (this.step == 1) {
        if (isEmpty(this.project.titulo)) {
          this.errors.push("<li>O título do projeto deve ser informado");
        }
        if (totalFR == 0) {
          this.errors.push(
            "<li>Os valores de fontes de recurso devem ser informados"
          );
        }
        if (isEmpty(this.project.ano)) {
          this.errors.push("<li>Ano do projeto deve ser informado");
        } else {
          if (parseInt(this.project.ano) < 1990) {
            this.errors.push("<li>Ano do projeto invalido");
          }
        }

        if (isEmpty(this.project.proponente)) {
          this.errors.push("<li>Nome do proponente deve ser informado");
        }
        if (!this.project.segmento) {
          this.errors.push("<li>Segmento deve ser informado");
        }
      } else if (this.step == 2) {
        // TODO: implement validate method
        if (
          this.project.locais &&
          this.project.locais.find((local) => isEmpty(local.estabelecimento))
        ) {
          this.errors.push(
            "<li>O nome do estabelecimento do evento deve ser informado nos locais"
          );
        }
        if (
          this.project.locais &&
          this.project.locais.find((local) => isEmpty(local.cidade))
        ) {
          this.errors.push("<li>A cidade deve ser informado nos locais");
        }
        if (
          this.project.locais &&
          this.project.locais.find((local) => isEmpty(local.estado))
        ) {
          this.errors.push("<li>O estado deve ser informado nos locais");
        }
      }
      if (this.errors.length > 0) {
        checked = false;
        this.showErrors = true;
      }

      return checked;
    },
    async saveProject(type) {
      if (type == null && !(await this.checkEssentials(this))) return false;

      try {
        this.loading = true;
        const caller = await this.$api();
        const {
          data: { result },
        } = await caller.post("project/save", this.project);
        if (!this.project._id && result._id) {
          this.project._id = result._id;
        }
        this.dirty = false;
        this.loading = false;
      } catch (error) {
        this.jsonData = error;
        this.loading = false;
      }
      return true;
    },
    goBack() {
      this.$router.push({
        path: "/projects",
      });
    },
    confirmBeforeLeaving() {
      return this.$refs.confirm.open("Saindo sem salvar", "Tem certeza?", {
        color: "red",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-stepper__step {
  cursor: pointer;
}

@media only screen and (max-width: 640px) and (orientation: portrait) {
  .v-stepper__header {
    margin: 0 6px;
  }

  .v-stepper--vertical .v-stepper__step {
    margin: 0px;
    padding: 16px;
  }

  .v-application--is-ltr .v-stepper--vertical .v-stepper__content {
    margin: -5px -48px -16px 24px;
  }

  .v-stepper__vertical .v-stepper__content {
    padding: 16px 60px 16px 16px;
  }
}

/* Landscape iPad */
@media only screen and (min-width: 1366px) and (orientation: landscape) {
  .project__wrapper .v-card__actions {
    bottom: 16px;
    width: 100%;
    position: fixed;
  }
}

/*Portrait iPad */
@media only screen and (min-width: 1024px) and (orientation: portrait) {
  .project__wrapper .v-card__actions {
    bottom: 16px;
    width: 100%;
    position: fixed;
  }
}
</style>
